import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

const fieldNames = [
  // default stuff
  "preTreatment",
  "patientCondition",
  "prognosis",
  "reasonsAlternativeTherapiesFailed",

];

export interface RemicadeState {
  formData: {
    // default stuff
    preTreatment: string
    patientCondition: string,
    prognosis: string,
    reasonsAlternativeTherapiesFailed: string,
  }
}

const initialState:RemicadeState = {
  formData: {
    preTreatment: "",
    patientCondition: "",
    prognosis: "",
    reasonsAlternativeTherapiesFailed: "",
  },
};

const RemicadeFormSlice = createSlice({
  name: "Remicade",
  initialState,
  reducers: {
    onClearRemicadeState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedRemicadeEditData: (state, {payload}: PayloadAction<any>) => {
      updatestate(payload, state, true);
      if (payload.formData) {
        state.formData.preTreatment =
          payload.formData?.preTreatment;
        state.formData.patientCondition =
          payload.formData?.patientCondition;
        state.formData.reasonsAlternativeTherapiesFailed =
          payload.formData?.reasonsAlternativeTherapiesFailed;
        state.formData.prognosis =
          payload.formData?.prognosis;
      }
    },
    onChangeRemicadeValue: (state, {payload}: PayloadAction<any>) => {
      console.debug("onChangeRemicadeValue", payload);
      if (payload.preTreatment || payload.preTreatment === "") state.formData.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.prognosis = payload.prognosis;
      updatestate(payload, state, false);
    },
    onAddMedication: (state, action) => {
    },
    onRemoveMedication: (state, action) => {
    },
  },
});


export const {
  onReceiveSpecialMedRemicadeEditData,
  onClearRemicadeState,
  onChangeRemicadeValue,
  onAddMedication,
  onRemoveMedication,
} = RemicadeFormSlice.actions;

export default RemicadeFormSlice.reducer;
export const selectRemicadeValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.remicadeForm?.formData;


function updatestate(payload: any, state, isEdit: boolean) {
  const data = payload.formData;
  console.debug("data", JSON.stringify(data));
  if (!data) return;

  resetBelow(fieldNames, "", data, state);
}

function resetBelow(fieldNames, changedField, data, state) {
  let reset = false;

  fieldNames.forEach((field) => {
    if (reset) {
      // Reset boolean fields to false
      if (typeof state.formData[field] === "boolean") {
        state.formData[field] = false;
      }
      // Reset string fields to empty string
      if (typeof state.formData[field] === "string") {
        state.formData[field] = "";
      }
      // Reset fields that can be null
      if (state.formData[field] === null || typeof state.formData[field] === "undefined") {
        state.formData[field] = null;
      }
      // Rest array fields to empty array
      if (Array.isArray(state.formData[field])) {
        state.formData[field] = [];
      }
    }

    // If this is the changed field, start resetting subsequent fields
    if (field === changedField) {
      reset = true;

      // Apply the change for the current field
      state.formData[field] = data[field];
    }
  });
}
