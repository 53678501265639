import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  Grid,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
  IconButton,
  Button,
  FormGroup,
  Checkbox,
} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";
import {onChangeDosisValue} from "../../../Dosis/dosisPlanSlice";
import {useLocation} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {calendarLanguage} from "pages/Wizard/containers/utils";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";
import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
  selectMedicineValues,
} from "../../../medicine/medicineSlice";

import {
  onChangeRemicadeValue,
  selectRemicadeValues,
} from "./RemicadeSlice";

const RemicadeForm = (props) => {
  // usestate for variant, numeric value:
  const [variant, setVariant] = useState(0);

  const {t, i18n}=useTranslation();
  const dispatch = UseAppDispatch();
  const RemicadeValues = UseAppSelector(selectRemicadeValues);
  const params = useLocation();
  const regex = /\d+$/;
  const isEdit = regex.test(params.pathname);
  const medicineValues=UseAppSelector(selectMedicineValues);
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const currentLang=i18n.language.toUpperCase();


  useEffect(() => {
    if (!isEdit) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
  }, [
    params.pathname,
  ]);


  useEffect(() => {
    console.debug("RemicadeValues changed", RemicadeValues);
    // Variant 1

    setVariant(0);
  }, [
    RemicadeValues,
  ]);


  useEffect(() => {
    console.debug("RemicadeValues changed", RemicadeValues);
    switch (variant) {
      default:
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", false);
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        break;
    }
  }, [
    variant,
  ]);


  // RemicadeValues.line1_

  return <>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>

      <Grid item container xs={12} spacing={2}>
      </Grid>


      <Grid item xs={12}

        hidden={
          !(variant === 1) &&
          !(variant === 12) &&
          !(variant === 13)
        }
      >
        <Stack hidden={
          !(variant === 1) &&
          !(variant === 12) &&
          !(variant === 13)
        } direction="row" alignItems="left" gap={1}>
          <WarningIcon color="primary" />
          <Typography>{t("remicade.warning.offlabel")}</Typography>
        </Stack>
        <Stack hidden={ !(variant === 11)} direction="row" alignItems="left" gap={1}>
          <WarningIcon color="primary" />
          <Typography>{t("remicade.warning.otherindication")}</Typography>
        </Stack>
        <Box sx={{m: 3}} />
        {[
          {label: "preTreatment", dataCy: "preTreatment", value: RemicadeValues.preTreatment},
          {label: "patientCondition", dataCy: "patientCondition", value: RemicadeValues.patientCondition},
          {label: "reasonsAlternativeTherapiesFailed", dataCy: "reasonsAlternativeTherapiesFailed", value: RemicadeValues.reasonsAlternativeTherapiesFailed},
          {label: "prognosis", dataCy: "prognosis", value: RemicadeValues.prognosis},
        ].map((field, index) => (
          <React.Fragment key={field.label}>
            <TextField
              style={{width: "100%"}}
              label={t(field.label)}
              data-cy={field.dataCy}
              InputLabelProps={{shrink: true}}
              value={field.value}
              variant="outlined"
              maxRows={4}
              minRows={4}
              multiline
              onChange={(event) =>
                dispatch(
                    onChangeRemicadeValue({
                      [field.label]: event.target.value,
                    }),
                )
              }
            />
            <Box sx={{m: 3}} />
          </React.Fragment>
        ))}
      </Grid>

      {variant > 0 && <Grid item xs={12}>
        <Typography
          data-cy="variant"

        >{t("remicade.variant")} {variant}</Typography>
      </Grid>}
    </LocalizationProvider>

  </>;
};

const mapStateToProps = (state) => {
  return {
    // Map state to props here if needed
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Map dispatch to props here if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(RemicadeForm);
